.panel-content-list-container {
  height: calc(100% - 120px);
}

.panel-content-detail-container {
  height: calc(100% - 50px);
}

.panel-company-detail-container {
  height: calc(100% - 120px);
}

.list-html {
  ul li {
    list-style-type: disc;
    margin-left: 1.5rem;
  }

  ol li {
    list-style-type: decimal;
    margin-left: 1.5rem;
  }
}
