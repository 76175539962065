/* General Class */

h1 {
  @apply text-3xl font-bold;
}

h2 {
  @apply text-2xl font-semibold py-1;
}

h3 {
  @apply text-xl font-semibold;
}

h4 {
  @apply font-semibold;
}

h5 {
  @apply text-xs font-semibold uppercase;
}

label {
  @apply text-sm font-medium;
}

/* Custom Classes */

.page-wrapper {
  height: calc(100vh - 64px);
  @apply px-8 py-5 w-full overflow-y-auto pb-80;
}

.standalone-kml-wrapper {
  height: calc(100vh - 80px);
}

.p-progress-spinner-circle {
  stroke: grey !important;
}

.data-section {
  @apply flex flex-col gap-y-8;
}

.data-section-row {
  @apply flex flex-row gap-5 flex-wrap;
}

.accordion-tab {
  @apply pt-3 pl-8 pr-3 pb-16;
}

// Manager
.sidebar-content-container {
  @apply flex flex-col justify-between h-full w-full;

  .manager-form-wrapper {
    @apply flex flex-col gap-y-5 pb-20 overflow-y-scroll;
  }

  .manager-footer {
    @apply flex flex-col gap-y-3 pt-3;
  }
}

// Dialog Manager
.dialog-content-container {
  @apply flex flex-col justify-between h-full w-full;

  .manager-form-wrapper {
    @apply flex flex-col gap-y-5;
  }

  .manager-footer {
    @apply flex flex-col gap-y-3 pt-3;
  }
}

/* Primereact Buttons */
.p-button {
  //border-radius: 5px !important;

  .p-button-label {
    font-weight: 600 !important;
  }
}

/* Primereact Datatable */
.p-datatable {
  /* Primereact Datatable */
  font-size: 14px !important;
  @apply overflow-hidden text-ellipsis whitespace-nowrap;

  // Header
  .p-datatable-thead > tr > th {
    @apply bg-slate-100;

    .p-inputtext {
      padding: 0.3rem 0.4rem;
      @apply text-sm font-semibold;
    }
  }

  .p-datatable-emptymessage {
    @apply text-slate-600 italic text-sm;
  }

  .p-paginator {
    @apply bg-slate-100;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    @apply text-slate-800 font-medium bg-slate-300 rounded-full;
  }

  .p-button {
    padding: 0.1rem !important;
    height: 30px !important;
    width: 30px !important;
  }

  .p-tag {
    padding: 0.2rem 0.5rem;
  }
}

#dashboard-table-with-filters.p-datatable {
  font-size: 14px !important;

  > .p-datatable-wrapper {
    height: calc(100vh - 248px);
  }
}

#dashboard-table-without-filters.p-datatable {
  font-size: 14px !important;

  > .p-datatable-wrapper {
    height: calc(100vh - 200px);
  }
}

// Autocomplete
.p-autocomplete {
  input {
    width: 100%;
  }
}

.p-autocomplete-item {
  @apply text-sm;
}

.p-autocomplete .p-autocomplete-multiple-container {
  width: 100%;
}

// Tabs
.p-tabview {
  height: 100%;

  .p-tabview-nav {
    @apply bg-slate-50;

    .p-tabview-nav-link {
      @apply bg-slate-50 px-0 mr-8;
    }
  }

  .p-tabview-panels {
    @apply px-0;
  }
}

.tabview-height {
  height: calc(100% - 130px);
}

.rct-item {
  cursor: default !important;
}

// Accordion
.p-accordion-header-text {
  @apply w-full;
}

// Confirm Dialog
.p-dialog {
  width: 50vw;

  .p-dialog-header {
    border-color: transparent !important;
  }

  .p-dialog-footer {
    border-color: transparent !important;
  }
}

// Dropdown
.active-custom-dropdown {
  @apply border-slate-800;

  .p-dropdown-trigger {
    @apply text-slate-400 transition-colors;

    &:hover {
      @apply text-slate-800;
    }
  }

  .p-dropdown-clear-icon {
    @apply text-slate-400 transition-colors;

    &:hover {
      @apply text-slate-800;
    }
  }
}

.p-dropdown-label,
.p-inputtext,
.p-dropdown-item {
  @apply text-sm;
}

// Rich Text Editor
.ql-editor {
  font-size: 16px;
  font-family:
    Montserrat,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
}

.ql-toolbar {
  @apply rounded-tl-lg rounded-tr-lg;
}

.ql-container {
  @apply rounded-bl-lg rounded-br-lg;
}
